import {
  ElegibilidadeExibirNomeTutorDisciplinaConfig,
  GetElegibilidadeExibirNomeTutorDisciplinaConfig,
} from './elegibilidade-exibir-nome-tutor-disciplina.config.types';

const elegibilidadeExibirNomeTutorDisciplinaConfig: ElegibilidadeExibirNomeTutorDisciplinaConfig = {
  marcas: ['wyden'],
  tipoCurso: [],
  modalidalidade: [],
  codigoCurso: [],
};

export const getElegibilidadeExibirNomeTutorDisciplinaConfig: GetElegibilidadeExibirNomeTutorDisciplinaConfig = () => {
  return elegibilidadeExibirNomeTutorDisciplinaConfig;
};
