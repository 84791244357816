import { Ambiente } from '../../types';
import { ElegibilidadeDashboardDisciplinaConfig, Params } from './elegibilidade-dashboard-disciplina.types';

const configDefault: ElegibilidadeDashboardDisciplinaConfig = {
  periodoMinimo: '2025.1',
  blocklist: [{ marca: 'idomed' }, { marca: 'estacio', tipoCurso: 'ESPECIALIZAÇÃO', periodoMaximo: '2025.1' }],
};

const configsPorAmbiente: Record<Ambiente, ElegibilidadeDashboardDisciplinaConfig> = {
  dev: { ...configDefault, periodoMinimo: '2024.2' },
  prd: configDefault,
  hml: configDefault,
  local: { ...configDefault, periodoMinimo: '2024.2' },
};

export const getElegibilidadeDashboardDisicplinaConfig = ({
  ambiente,
}: Params): ElegibilidadeDashboardDisciplinaConfig => configsPorAmbiente[ambiente];
